<template>
  <div ref="root">
    <v-list-item
      @click="toggle"
      :title="title"
      :subtitle="subtitle"
      :density="density"
      :disabled="disabled"
      :lines="subtitle ? 'one' : 'two'"
      class="rounded-lg px-2"
    >
      <template #prepend>
        <v-icon>{{ active ? "check_box" : "check_box_outline_blank" }}</v-icon>
      </template>
      <template #append>
        <TipInfo v-if="useTipInfo" :text="subtitle" />
      </template>
    </v-list-item>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { first } from "lodash";

export default defineComponent({
  name: "CheckBox",
  props: {
    modelValue: {},
    density: { type: String as PropType<any>, default: "compact" },
    title: String,
    subtitle: String,
    reverse: Boolean,
    trueValue: { type: [Number, String, Boolean], default: true },
    falseValue: { type: [Number, String, Boolean], default: false },
    disabled: { type: Boolean, default: false },
  },
  setup(props) {
    const root = ref<HTMLElement>();
    const useTipInfo = ref(false);

    const checkEllipsis = (el: HTMLElement) => {
      const styles = getComputedStyle(el);
      const widthEl = parseFloat(styles.width);
      const ctx = document.createElement("canvas").getContext("2d")!;
      ctx.font = `${styles.fontSize} ${styles.fontFamily}`;
      const text = ctx.measureText(el.innerText);

      let extra = 0;
      extra += parseFloat(styles.getPropertyValue("border-left-width"));
      extra += parseFloat(styles.getPropertyValue("border-right-width"));
      extra += parseFloat(styles.getPropertyValue("padding-left"));
      extra += parseFloat(styles.getPropertyValue("padding-right"));
      return text.width > widthEl - extra;
    };

    onMounted(() => {
      if (props.subtitle) {
        const c = root.value?.getElementsByClassName("v-list-item-subtitle");
        const e = first(c) as HTMLElement | null;
        useTipInfo.value = !!e && checkEllipsis(e);
      }
    });

    return {
      root,
      useTipInfo,
    };
  },
  computed: {
    active: {
      set(value: any) {
        this.$emit("update:modelValue", this.getValueFromBool(value));
      },
      get() {
        return this.getBoolFromValue(this.modelValue);
      },
    },
  },
  methods: {
    getBoolFromValue(value: any) {
      const v = value === this.trueValue ? true : false;
      return this.reverse ? !v : v;
    },
    getValueFromBool(value: any) {
      const v = value ? this.trueValue : this.falseValue;
      return this.reverse ? this.getReverseValue(v) : v;
    },
    getReverseValue(value: any) {
      return value == this.trueValue ? this.falseValue : this.trueValue;
    },
    toggle() {
      this.active = !this.active;
    },
  },
});
</script>
